.menu-wrap {
  padding: 8px 0;
  gap: 0px;
  border-radius: 8px;
  background: #fff;
  border: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
}
.menu-wrap .menu-item-hover {
  user-select: none;
  display: flex;
  align-items: center;
  gap: 10px;
  color: rgb(34, 34, 34);
  font-size: 14px;
  font-weight: 500;
  background: inherit;
  margin-bottom: 0;
  padding: 0 10px;
  line-height: 36px;
}
.menu-wrap .menu-item-hover .hkgai-icon {
  font-size: 20px;
}
.menu-wrap .menu-item-hover .arco-icon {
  margin: 0;
  font-size: 20px;
  color: rgb(34, 34, 34);
}
.menu-wrap .menu-item-hover:hover {
  background: rgb(247, 248, 250);
}
.menu-wrap .menu-item-hover:last-child {
  margin-bottom: 0px;
  margin-top: 4px;
}

.term-of-use-modal .arco-modal-content {
  font-size: 14px;
  font-weight: 400;
}