@tailwind utilities;

html,
body {
    padding: 0;
    margin: 0;
    font-family: PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
    background: #f2f2f2;
}

/* div {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
} */

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

.h5-page {
    width: 100vw;
    height: 100vh;
    background: #f2f2f2;
    display: flex;
    flex-direction: column;
    background: url('../static/h5/page-bg.png');
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;

    .h5-page-title {
        margin-top: 128px;
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        text-align: center;
        color: rgba(27, 37, 89, 1);
        position: relative;
    }

    .sub-title {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        margin-top: 10px;
    }

    .hkgai-icon-hk-copilot {
        width: 200px;
        height: 43px;
        margin: 10px auto 0;
    }

    .page-body {
        width: 100%;
        height: 242px;
        margin-top: 60px;
        background: url(../static/h5/page-body.png);
        background-size: contain;
        background-position: bottom right;
        background-repeat: no-repeat;
        padding: 26px 0 0 180px;
        font-size: 10px;
        font-weight: 400;
        color: var(--text-3, rgba(134, 144, 156, 1));
    }

    .h5-page-message {
        display: flex;
        align-items: center;
        margin: 0 24px;
        padding: 9px var(--16, 16px) 9px var(--20, 20px);
        gap: var(--8, 8px);
        border-radius: 60px;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
        border: 1px solid var(--border-2, rgba(229, 230, 235, 1));
        background-color: #fff;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        position: relative;
        top: -92px;
    }

    .hkgai-icon-notification {
        width: 20px;
        height: 20px;
    }

    .h5-page-footer {
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 30px;
    }
}

.h5-page {
    width: 100vw;
    height: 100vh;
    background: #F2F2F2;
    display: flex;
    flex-direction: column;
    background: url('../static/h5/page-bg.png');
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;


    .h5-page-title {
        margin-top: 128px;
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        text-align: center;
        color: rgba(27, 37, 89, 1);
        position: relative;
    }

    .sub-title {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        margin-top: 10px;
    }

    .hkgai-icon-hk-copilot {
        width: 200px;
        height: 43px;
        margin: 10px auto 0;
    }

    .page-body {
        width: 100%;
        height: 242px;
        margin-top: 60px;
        background: url(../static/h5/page-body.png);
        background-size: contain;
        background-position: bottom right;
        background-repeat: no-repeat;
    }

    .h5-page-message {
        display: flex;
        align-items: center;
        margin: 0 24px;
        padding: 9px var(--16, 16px) 9px var(--20, 20px);
        gap: var(--8, 8px);
        border-radius: 60px;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
        border: 1px solid var(--border-2, rgba(229, 230, 235, 1));
        background-color: #fff;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        position: relative;
        top: -90px;
    }

    .hkgai-icon-notification {
        width: 20px;
        height: 20px;
    }

    .h5-page-footer {
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 30px;
    }
}
