.resources-modal {
  width: 960px;
  height: 332px;
  padding: 16px;
  border-radius: var(--16, 16px);
  background: rgb(255, 255, 255);
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.08);
}
.resources-modal .arco-modal-content {
  padding: 0;
}
.resources-modal .arco-modal-close-icon {
  font-size: 16px;
  color: rgb(78, 89, 105);
  top: 26px;
}
.resources-modal .arco-tabs-header-nav-line.arco-tabs-header-nav-horizontal > .arco-tabs-header-scroll .arco-tabs-header-title:first-of-type {
  margin-left: 0;
}
.resources-modal .arco-tabs-header-nav-line .arco-tabs-header-title {
  padding: 0 0 16px;
}
.resources-modal .arco-tabs-header-ink {
  height: 3px;
  border-radius: 10px;
}
.resources-modal .arco-tabs-header-extra {
  padding-right: 32px;
}
.resources-modal .extra-action {
  position: relative;
  bottom: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.resources-modal .extra-action .extra-action-button {
  height: var(--32, 32px);
  padding: 5px var(--16, 16px);
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
}
.resources-modal .resources {
  display: flex;
  flex-direction: column;
  height: 246px;
  gap: 16px;
}
.resources-modal .resources-content {
  flex: 1;
}
.resources-modal .resources-input {
  padding: 16px;
  width: 100%;
  display: flex;
  gap: 12px;
  align-items: flex-start;
  height: 101px;
  border-radius: var(--8, 8px);
  border: 1px solid rgb(229, 230, 235);
  background-color: #fff;
}
.resources-modal .resources-input.error {
  border-color: red;
}
.resources-modal .resources-input-textarea {
  flex: 1;
  display: flex;
  align-items: flex-end;
}
.resources-modal .resources-input .hkgai-icon-link {
  width: 20px;
  height: 20px;
  color: rgb(204, 204, 204);
  opacity: 0.35;
}
.resources-modal .resources-input .hkgai-icon-PaperPlaneRightBlue {
  width: 24px;
  height: 24px;
}
.resources-modal .resources-input .arco-textarea {
  flex: 1;
  height: 68px;
  background: #fff;
  border: 0px;
  outline: none;
  caret-color: #165dff;
  resize: none;
  padding: 0 0 4px;
}
.resources-modal .resources-upload {
  padding: 16px;
  width: 100%;
  height: 101px;
  border-radius: var(--8, 8px);
  border: 1px dashed rgb(229, 230, 235);
  background-color: var(--fill-2, rgb(242, 243, 245));
}
.resources-modal .resources-upload:hover {
  border-color: var(--Color, rgb(22, 93, 255));
}
.resources-modal .resources-upload .arco-upload-trigger {
  width: 100%;
}
.resources-modal .resources-upload .trigger {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  text-align: center;
}
.resources-modal .resources-upload .trigger-title {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: rgb(29, 33, 41);
}
.resources-modal .resources-upload .trigger-tips {
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: rgb(134, 144, 156);
}
.resources-modal .resources-upload .trigger .hkgai-icon-Plus {
  width: 14px;
  height: 14px;
  margin: 0 aut;
}
.custom-checkbox-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding: 4px;
  padding-right: 24px;
  height: 36px;
  border-radius: 4px;
  background: rgb(240, 240, 240);
  font-size: 16px;
  font-weight: 400;
  border: 1px solid transparent;
  overflow: hidden;
  width: 130px;
}

.custom-checkbox-card-title {
  font-size: 12px;
  line-height: 14px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.custom-checkbox-card:hover,
.custom-checkbox-card-checked {
  border-color: rgb(var(--primary-6));
}

.custom-checkbox-card-checked {
  border: 1px solid var(--Color, rgb(22, 93, 255));
}

.custom-checkbox-card-mask {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-checkbox-card-progress {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: #86909c;
}